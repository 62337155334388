import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>If you have any questions or need further information, feel free to contact us:</p>
      <p>Email: info@buffbox.ca</p>
      <p>Phone: +1 647 894 6314</p>
      <p>Address: N/A</p>
    </div>
  );
}

export default Contact;
