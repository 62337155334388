import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import buffBoxRoom from '../assets/1.png'; // Import the image
import buffBoxRoom2 from '../assets/3.png'; // Import the image

function Home() {
  return (
    <div className="home">
      <h1 className="home-heading">The first ever private workout experience</h1>
      <section className="gray-section">
        <p>
          Only <span className="highlight">$4.99/hour</span>
        </p>
        <Link to="/reserve" className="button">Get a timeslot</Link>
      </section>
      <section className="info-section">
        <h2>How does Buff Box work?</h2>
        <div className="info-content">
          <div className="steps">
            <div className="step">
              <div className="step-number">1</div>
              <div className="step-description">
                <h3>Reserve a Timeslot</h3>
                <p>Book your private workout time through our easy online system. Choose the time that best suits your schedule.</p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <div className="step-description">
                <h3>Show Up</h3>
                <p>Arrive at your reserved time to enjoy a clean, private workout space. Our facilities are designed for your comfort and safety.</p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <div className="step-description">
                <h3>Work Out</h3>
                <p>Focus on your fitness goals without distractions. Our equipment is sanitized and ready for you.</p>
              </div>
            </div>
          </div>
          <div className="info-images">
            <img src={buffBoxRoom} alt="Buff Box Room" />
            <img src={buffBoxRoom2} alt="Buff Box Room" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
