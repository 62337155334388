import React from 'react';

function Reserve() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Page in Development</h1>
    </div>
  );
}

export default Reserve;


/*import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimeslotSelector from './TimeslotSelector';

function Reserve() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [step, setStep] = useState(1);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStep(2);
  };

  return (
    <div>
      {step === 1 && (
        <div>
          <h1>Reserve a Timeslot</h1>
          <p>Select a day to reserve a timeslot:</p>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
          />
        </div>
      )}
      {step === 2 && (
        <TimeslotSelector date={selectedDate} />
      )}
    </div>
  );
}

export default Reserve;
*/