import React from 'react';

function About() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Page in Development</h1>
    </div>
  );
}

export default About;
